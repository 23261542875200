<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row>
            <b-col cols="12" md="8">
                <b-card no-body class="invoice-actions">
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="2">
                                <label for="date_start">Fecha Inicio</label>
                                <flat-pickr id="date_start" v-model="filter.fechaInicio"
                                    class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                    placeholder="DD/MM/YYYY" />
                            </b-col>
                            <b-col cols="12" md="2">
                                <label for="date_start">Fecha Fin</label>
                                <flat-pickr id="date_start" v-model="filter.fechaFin"
                                    class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                    placeholder="DD/MM/YYYY" />
                            </b-col>

                            <!-- 
                                1334245
                                <b-col md="6">
                                <b-form-group label="Busqueda Por" label-for="search_id" label-class="font-weight-bold">
                                    <v-select id="search_id"
                                        class="select-size-sm"
                                        v-model="filter.category_key"
                                        :options="categories"
                                        :reduce="option => option.key" 
                                        label="label" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectSearchCategory">
                                    </v-select>
                                </b-form-group>
                            </b-col> -->

                            <b-col cols="12" md="4">
                                <b-form-group label="Almacén" label-for="almacen_Id">
                                    <v-select class="select-size-sm" id="almacen_Id" v-model="filter.almacenId" :options="warehouses"
                                        :reduce="option => option.almacen_Id" label="nombre"
                                        placeholder="Selecciona una opción" :clearable="false"
                                        :searchable="false"
                                        size="sm">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <div class="demo-inline-spacing">
                                    <b-button :disabled="loading" @click="getTickets" size="sm" variant="primary"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                        <feather-icon icon="SearchIcon" />
                                        Buscar
                                    </b-button>

                                    <!-- <b-button size="sm"
                                        variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                        <feather-icon icon="DownloadIcon" />
                                        Descargar
                                    </b-button> -->
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import moment from 'moment';
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,  BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers';
import * as XLSX from 'xlsx';

import WareHousesProvider from "@/providers/WareHouses"
const WareHousesResource = new WareHousesProvider()

import ReportsProvider from '@/providers/Reports';
const ReportsResource = new ReportsProvider();

export default {
    name: "TicketVsStocks",
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,        
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        BTooltip, 
        VBTooltip,

        //
        flatPickr,
        vSelect,
    },
    setup() {

        const loading = ref(false);
        const warehouses = ref([{
            almacen_Id: 0,
            nombre: 'TODOS',
        }]);
        const sales = ref([]);
        const categories = ref([
            {   key: 'lines', label: 'Lineas',  subquery: false},
            {   key: 'classificator', label: 'Clasificadores', subquery: true },
            {   key: 'particular_data', label: 'Datos Particulares', subquery: true },
            {   key: 'levels', label: 'Niveles', subquery: true },
            {   key: 'names', label: 'Nombres', subquery: false },
            {   key: 'primary_key', label: 'Clave Principal', subquery: false },
            {   key: 'supplier_key', label: 'Clave Proveedor', subquery: false },
            {   key: 'bar_code', label: 'Código Barras', subquery: false },
            {   key: 'images', label: 'Imágenes', subquery: false },
        ])
        const filter = ref({
            fechaInicio: moment().startOf('month').format('YYYY-MM-DD'),
            fechaFin: moment().format('YYYY-MM-DD'),
            almacenId: 0,
            category_key: null,
            precioEmpresaId: null,
            category_key: null,
            warehouse_id: null,
            attribute_id: null,
            classificator_id: null,
            valor_clasif_id: null,
            idClasificador: null,
            idClasificadorValor: null,
            idPrecioLista: null,
        });

        const getWareHouses = async () => {
            loading.value = true
            const { data } = await WareHousesResource.index()
            loading.value = false
            if (data.isSuccesful) {
                data.data.map((item) => {
                    warehouses.value.push({
                        almacen_Id: item.almacen_Id,
                        nombre: item.nombre,
                    })
                })
            }
        }

        const selectSearchCategory = (evt) => {

        }

        const getTickets = async () => {
            try {
                loading.value = true
                const { data } = await ReportsResource.ticketVsStocks(filter.value)
                loading.value = false
                if (data.isSuccesful) {
                    sales.value = data.data
                } else {
                    helper.danger(data.message)
                }
                console.log(sales.value)
            }catch(e) {
                helper.handleResponseErrors(e)   
            } finally {
                loading.value = false
            }
        }

        onMounted(async () => {
            await getWareHouses()
        })

        return {
            loading,
            filter,
            warehouses,
            sales,
            categories,

            //Methods
            selectSearchCategory,
            getTickets,
        }
        
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
